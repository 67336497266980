import destination_marker from '@/assets/img/dest_marker.svg'
import pickup_marker from '@/assets/img/pickup_marker.svg'
import vehicle_marker from '@/assets/img/gray_bus.svg'

export default {
  data () {
    return {
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false
      },
      mapInstance: null
    }
  },
  computed: {
    destMarker () {
      return destination_marker
    },
    pickupMarker () {
      return pickup_marker
    },
    vehicleMarker () {
      return vehicle_marker
    }
  },
  methods: {
    getPathFromPolyline (overviewPolyline) {
      if(!overviewPolyline) return []
      const encodedPolyline = JSON.parse(overviewPolyline)
      return window.google.maps.geometry.encoding.decodePath(
        encodedPolyline.points)
    }
  }
}
