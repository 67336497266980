<template>
  <div class="trip-detail-holder">
    <div
      v-if="loading"
      class="d-flex justify-content-center align-items-center"
    >
      <div class="spinner-border" role="status"></div>
    </div>
    <template v-else>
      <div class="spanned-map">
        <google-map
          id="map-preview"
          ref="tripMap"
          :options="mapOptions"
          :zoom="12"
        >
          <google-map-marker
            :icon="pickupMarker"
            :position="pickUpCordinatesForRoute(trip.route)"
            :key="`preview-pickup-${trip.route.id}`"
          />

          <google-map-marker
            :icon="destMarker"
            :position="destinationCordinatesForRoute(trip.route)"
            :key="`preview-destination-${trip.route.id}`"
          />
          <google-map-polyline
            :path="getPathFromPolyline(trip?.route?.overview_polyline)"
            :strokeWeight="3"
            strokeColor="#000000"
          ></google-map-polyline>
        </google-map>
        <!-- <div
          class="absolute right-10 top-60 rounded-full bg-white h-10 w-10 flex justify-center items-center shadow-md z-50"
        >
          <button class="bg-transparent border-none" @click="handleNextTrip">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#000000"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M13 17l5-5-5-5M6 17l5-5-5-5" />
            </svg>
          </button>
        </div> -->
      </div>
    </template>
    <div class="" v-if="trip">
      <!-- <div
        class="absolute left-10 top-60 rounded-full bg-white h-10 w-10 flex justify-center items-center shadow-md z-50"
      >
        <button class="bg-transparent border-none" @click="handlePreviousTrip">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#000000"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M11 17l-5-5 5-5M18 17l-5-5 5-5" />
          </svg>
        </button>
      </div> -->
      <div
        class="col-12 ml-16"
        :class="isCompletedTrip ? 'col-xl-6' : 'col-xl-6'"
      >
        <div class="card m-4">
          <div class="card-header justify-content-between">
            <h4 class="card-header-title">Trip Information</h4>
          </div>
          <div class="card-body p-2">
            <div class="list-group list-group-flush mb-4">
              <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col">
                    <small>Route</small>
                  </div>
                  <div class="col-auto">
                    <route-description
                      @click="viewRoute(this.trip.route.destination)"
                      class-name="sh-link"
                      :pickup="this.trip?.route?.pickup || ''"
                      :destination="this.trip?.route?.destination || ''"
                    >
                    </route-description>
                  </div>
                </div>
              </div>
                <div class="list-group-item" v-if="!this.trip.start_trip">
                <div class="row align-items-center">
                  <div class="col">
                    <small>Date</small>
                  </div>
                  <div class="col-auto">
                    {{ trip.created_at | date('d MMMM, yyyy') }}
                  </div>
                </div>
              </div>

              <div class="list-group-item" v-if="this.trip.start_trip">
                <div class="row align-items-center">
                  <div class="col">
                    <small>Started At</small>
                  </div>
                  <div class="col-auto">
                    {{ formatTripTime(this.trip.trip_start_time) }}
                  </div>
                </div>
              </div>

          <div class="list-group-item" v-if="markActor">
                <div class="row align-items-center">
                  <div class="col">
                    <small>Marked by</small>
                  </div>
                  <div class="col-auto">
                    {{ `${markActor.fname} ${markActor.lname}` }}
                  </div>
                </div>
              </div>

              <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col">
                    <small>Distance</small>
                  </div>
                  <div class="col-auto">
                    {{ this.trip?.route?.distance || 'N/A' }}
                  </div>
                </div>
              </div>
              <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col">
                    <small>Duration</small>
                  </div>
                  <div class="col-auto">
                    {{ this.trip?.route?.duration || 'N/A' }}
                  </div>
                </div>
              </div>
               <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col">
                    <small>Status</small>
                  </div>
                  <div class="col-auto">
                    <span
                      class="badge text-white"
                      :class="this.trip?.route?.status === 1 ? 'bg-success' : 'bg-danger'"
                      >{{ this.trip?.route?.status === 1 ? 'Active' : 'Inactive' }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col">
                    <small>Route Code</small>
                  </div>
                    <div class="col-auto" v-if="this.trip?.route?.route_code">
                      <span class="sh-link" @click="viewRoute(this.trip?.route?.id)">{{
                        this.trip?.route?.route_code
                      }}</span>
                    </div>
                    <div class="col-auto" v-else>
                      {{ 'N/A' }}
                    </div>
                </div>
              </div>
             <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col">
                    <small>Driver</small>
                  </div>
                  <div  class="col-auto d-flex align-items-center">
                    <user-avatar :user="this.trip?.driver" size="sm"></user-avatar>
                    <div class="d-flex flex-column align-items-end">
                      <router-link
                        v-if="this.trip?.driver && this.trip?.driver?.id"
                        class="ml-2 sh-link"
                        :to="{
                          name: 'ShowDriver',
                          params: { driverId: this.trip?.driver?.id },
                        }"
                      >
                        <span>{{ driverName }}</span>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col">
                    <small>Driver's Phone</small>
                  </div>
                  <div class="col-auto d-flex align-items-center" v-if="this.trip?.driver">
                    <a
                      v-if="this.trip?.driver && this.trip?.driver?.phone"
                      :href="'tel:' + this.trip?.driver?.phone"
                    >
                      {{ this.trip?.driver?.phone }}
                    </a>
                    <span v-else>N/A</span>
                  </div>
                </div>
              </div>
              <div class="list-group-item" v-if="vehicleData">
                <div class="row align-items-center">
                  <div class="col">
                    <small>Vehicle</small>
                  </div>
                  <div class="col-auto link" v-if="this.trip?.vehicle_id">
                    <router-link
                      class="sh-link"
                      :to="{
                        name: 'ShowVehicle',
                        params: { vehicleId: this.trip?.vehicle_id },
                      }"
                    >
                      <img
                        v-if="vehicleData.tracking_id?.length"
                        src="@/assets/img/gps.png"
                        width="15"
                        height="15"
                        alt="Vehicle has tracker onboard"
                      />

                      {{ vehicleData.brand }} {{ vehicleData.name }} ({{
                        vehicleData.registration_number
                      }})
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="list-group-item">
                <div class="row align-items-center">
                  <div class="col">
                    <small>Partner</small>
                  </div>
                  <div class="col-auto link" v-if="partnerData">
                    <router-link
                      class="sh-link"
                      :to="{
                        name: 'PartnerDetails',
                        params: {
                          partnerId: partnerData.id,
                          accountSid: partnerData.account_sid,
                        },
                      }"
                    >
                      <img
                        v-if="vehicleData.tracking_id?.length"
                        src="@/assets/img/gps.png"
                        width="15"
                        height="15"
                        alt="Vehicle has tracker onboard"
                      />

                      {{ partnerData.company_name }}
                    </router-link>
                    <br />
                    {{ partnerData.company_phone }}
                  </div>
                  <div v-else>N/A</div>
                </div>
              </div>
            </div>
            <!--
            <div
        class="mt-3"
        v-if="isCompletedTrip && routeBusStopIDs && routeBusStopIDs.length"
      >
        <TripEventsTimeLine
          :driverId="driverId"
          :vehicleId="timeLineVehicleId"
          :routeBusStopIDs="routeBusStopIDs"
          :startDate="toIsoString(trip.start_trip)"
          :endDate="toIsoString(trip.end_trip)"
        />
      </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatar from '@/components/core/UserAvatar.vue'
import routeBusStop from '@/mixins/route_bus_stop'
import mapComponent from '@/mixins/map-component'
import RouteDescription from '@/components/modules/routes/RouteDescription'
import TripDetail from './trip-detail-mixin'
import { busMarker } from '@/assets/svg/inline-svg'
import moment from 'moment'
// import filters from '@/filters'

export default {
  components: {
    RouteDescription,
    UserAvatar,
  },
  mixins: [routeBusStop, mapComponent, TripDetail],
  data() {
    return {
      io: null,
      hasBeenPannedTo: false,
      activeTripsMarker: null,
      currentVehiclePosition: null,
      loading: false,
      errorLoading: false,
      error: null,
      routeBusStopIDs: [],
      currentPositionMarker: '',
      markActor: [],
      fatchingMarkActor: false,
      tripsData: [],
      currentIndex: 0,
      tripData: {},
      page: 0,
      currentNextInfo: {},
      currentTripId: Number(this.$route.params.tripId.split('__')[1]),
    }
  },
  mounted() {
    if (this.$refs.tripMap) {
      this.$refs.tripMap.$_getMap().then((v) => {
        this.mapInstance = v
        this.mapInstance.panTo(this.pickUpCordinatesForRoute(this.trip.route))
      })
    }
    this.fetchTripRoouteBusStops()
    this.initWebSocket()
    this.getMarkAsHappenedActor()
    this.tripsData = JSON.parse(localStorage.getItem('trips'))
    this.currentIndex = this.tripsData.findIndex(
        (itm) => itm.id === Number(this.currentTripId)
      )
    this.currentNextInfo = this.tripsData[this.currentIndex]
  },
  watch: {
    trip(val) {
      this.fetchTripRoouteBusStops()
    },
  },
  beforeDestroy() {
    this.$socketController.off(`trips:${this.trip.id}`)
    this.$socketController.off(`vehicles:${this.trip.vehicle_id}`)
  },
  methods: {
    // handleNextTrip() {
    //   this.currentIndex = this.tripsData.findIndex(
    //     (itm) => itm.id === Number(this.currentTripId)
    //   )
    //   if (!this.tripsData[this.currentIndex + 1]) {
    //     this.page++
    //     this.currentIndex = 0
    //     this.getTrips()
    //       .then((res) => {
    //         this.tripsData = res?.data?.data
    //         this.tripData = this.tripsData[this.currentIndex]
    //       })
    //       .catch(() => {})
    //   } else {
    //     const trip = this.tripsData[this.currentIndex + 1]
    //     this.currentNextInfo = trip
    //     this.currentTripId = trip.id
    //     this.$router.replace({
    //       name: 'TripDetails',
    //       params: { tripId: `__${this.currentTripId}` },
    //     })
    //   }
    // },

    // handlePreviousTrip() {
    //   this.currentIndex = this.tripsData.findIndex(
    //     (itm) => itm.id === Number(this.currentTripId)
    //   )
    //   if (!this.tripsData[this.currentIndex - 1]) {
    //     this.page--
    //     this.currentIndex = 0
    //     this.getTrips(page)
    //       .then((res) => {
    //         this.tripsData = res?.data?.data
    //         this.tripData = this.tripsData[this.currentIndex]
    //       })
    //       .catch(() => {})
    //   } else {
    //     const trip = this.tripsData[this.currentIndex - 1]
    //     this.currentNextInfo = trip
    //     this.currentTripId = trip.id
    //     this.$router.replace({
    //       name: 'TripDetails',
    //       params: { tripId: `__${this.currentTripId}` },
    //     })
    //   }
    // },
    async getTrips() {
      this.params = params
			const limit = params ? params.limit : 10
			const currentPage = this.page ? this.page : 1
			// const from = params ? params.from : null

			const baseUrl = '/v1/trips/upcoming'
			let url = `${baseUrl}?limit=${limit}&page=${currentPage}&metadata=true&sort[created_at]=desc&`
			if (params?.from || params?.to) {
				if (params?.from) {
					url += `from=${params.from}&`
				}
				if (params?.to) {
					url += `to=${params.to}`
				}
			}
			if (params?.search) {
				url += `&search=${params.search}`
			}
			const result = await this.axios.get(url)
			const data = result.data.data
      this.tripsData = data
      // this.$store.dispatch('setTrips', data)
      localStorage.setItem('trips', JSON.stringify(data))
			return {
				data: data,
				meta_data : result.data?.metadata,
			}
    },
    formatTripTime(item) {
      if (item) {
        return moment(item).format('DD MMM YYYY, hh:mm a')
      }
      return 'N/A'
    },
    async getMarkAsHappenedActor() {
      try {
        this.fatchingMarkActor = false
        const response = await this.axios.get(
          `/audit/v1/audits?operationType=trip-update&sort=eventDate:asc&entityId=${this.currentTripId}&entityName=trip`
        )
        this.markActor = response.data[0]
      } catch (error) {
        // this.$toastr.e('Something went wrong', 'Error')
      } finally {
        this.fatchingMarkActor = false
      }
    },
    async initWebSocket() {
      if (this.isCompletedTrip) {
        return
      }

      if (this.isUpcomingTrip && this.vehicleData?.tracking_id?.length) {
        await this.$socketController.joinRoom(
          `vehicles:${this.trip.vehicle_id}`
        )
        this.$socketController.on(
          `vehicles:${this.trip.vehicle_id}:new-position`,
          (data) => {
            if (data) {
              this.updateTripInformation(this.trip, data)
            }
          }
        )
        return
      }

      if (this.isUpcomingTrip) {
        await this.$socketController.joinRoom(`drivers:${this.trip.driver_id}`)
        this.$socketController.on(
          `drivers:${this.trip.driver_id}:new-position`,
          (data) => {
            if (data) {
              this.updateTripInformation(this.trip, data)
            }
          }
        )
        return
      }

      this.$socketController.on(`trips:${this.trip.id}`, (data) => {
        if (data) {
          this.updateTripInformation(this.trip, data)
        }
      })
      this.$socketController.emit(`trips:${this.trip.id}`)
    },

    addBusMarker(
      prevLat,
      prevLng,
      lat,
      lng,
      title,
      startingPoint,
      description,
      tripId
    ) {
      const latLng = new window.google.maps.LatLng(lat, lng)
      const bearing = this.computeHeading(prevLat, prevLng, lat, lng)
      const marker = new window.google.maps.Marker({
        position: latLng,
        map: this.mapInstance,
        collisionBehavior: 'REQUIRED_AND_HIDES_OPTIONAL',
        icon: {
          // labelOrigin: {x: 8, y: 35}, //below marker
          url: busMarker(bearing),
          anchor: new window.google.maps.Point(33, 45),
          size: new window.google.maps.Size(66, 98),
          rotation: bearing,
        },
      })

      // Using this to handle events due to asset dimension used in rendered marker
      const tMarker = new window.google.maps.Marker({
        position: latLng,
        map: this.mapInstance,
        optimized: false,
        visible: true,
        label: {
          text: title,
          className: 'marker-label',
        },
        collisionBehavior: 'REQUIRED_AND_HIDES_OPTIONAL',
        icon: {
          labelOrigin: { x: 14, y: -14 }, // above marker
          url: 'https://maps.gstatic.com/mapfiles/transparent.png',
          size: new window.google.maps.Size(20, 46),
          anchor: new window.google.maps.Point(10, 23),
        },
      })

      let infoWindow
      let infoWindowRef = null
      const infoWindowOffSet = new window.google.maps.Size(0, 0)
      tMarker.addListener('click', () => {
        infoWindowRef = this.openInfoWindow(
          tMarker,
          title,
          startingPoint,
          description,
          infoWindowOffSet
        )
        this.panAndZoom(marker.getPosition())
        this.highlightTrip(tripId)
      })
      tMarker.addListener('mouseover', () => {
        infoWindow = infoWindowRef = this.openInfoWindow(
          tMarker,
          title,
          startingPoint,
          description,
          infoWindowOffSet
        )
      })
      tMarker.addListener('mouseout', () => {
        if (infoWindow) {
          infoWindow.close()
        }
      })

      return { marker, tMarker, infoWindow: () => infoWindowRef }
    },
    openInfoWindow(busMarker, title, start, destination, pixelOffset = null) {
      const img = require('@/assets/img/mini_marker_dark.svg')
      const contentString =
        '<div class="info-window">' +
        `<div class="info-window__source"><p class="d-flex mb-3"><span class="source-badge"></span> <span class="text">${start}</span></p></div>` +
        `<div class="info-window__destination"><p class="d-flex align-items-start mb-0"><img src="${img}" class="icon" /> <span class="text"> ${destination}</span></p></div>` +
        '</div>'
      const extras = pixelOffset ? { pixelOffset } : {}
      const infoWindow = new window.google.maps.InfoWindow({
        content: contentString,
        ...extras,
      })
      infoWindow.open(this.mapInstance, busMarker)
      return infoWindow
    },
    setTripCurrentMarker(tripInfo, newLatitude = null, newLongitude = null) {
      const startingPoint = tripInfo.route.pickup_coordinate
        .split(',')
        .map((i) => parseFloat(i.trim()))
      const [lat, lng] = startingPoint
      if ((lat && lng) || (newLatitude && newLongitude)) {
        this.activeTripsMarker = this.addBusMarker(
          lat || 0,
          lng || 0,
          newLatitude || lat,
          newLongitude || lng,
          `${tripInfo.route.route_code}`,
          `B/S: ${tripInfo?.route?.pickup}`,
          `Destination: ${tripInfo.route.destination}`,
          tripInfo.id
        )
      }
    },
    updateTripInformation(tripInfo, locationInfo) {
      const prevTrackingInfo = this.currentVehiclePosition
      const { position_latitude, position_longitude } = locationInfo
      if (!this.currentVehiclePosition) {
        this.setTripCurrentMarker(
          tripInfo,
          position_latitude,
          position_longitude
        )
      } else {
        const newLatLng = new window.google.maps.LatLng(
          position_latitude,
          position_longitude
        )
        this.activeTripsMarker.marker.setPosition(newLatLng)
        this.activeTripsMarker.tMarker.setPosition(newLatLng)
      }

      if (
        prevTrackingInfo &&
        this.coordinateChanged(prevTrackingInfo, locationInfo)
      ) {
        const icon = this.activeTripsMarker.marker.getIcon()
        const rotation = this.computeHeading(
          prevTrackingInfo.position_latitude,
          prevTrackingInfo.position_longitude,
          position_latitude,
          position_longitude
        )
        icon.url = busMarker(rotation)
        this.activeTripsMarker.marker.setIcon(icon)
      }
      this.currentVehiclePosition = {
        lat: position_latitude,
        lng: position_longitude,
      }

      if (this.mapInstance && !this.hasBeenPannedTo) {
        this.mapInstance.panTo(
          window.google.maps.geometry.spherical.computeOffset(
            this.currentVehiclePosition,
            1000,
            45
          )
        )
        this.hasBeenPannedTo = true
      }
    },
    coordinateChanged(previousCoordinateData, currentCoordinateData) {
      return (
        previousCoordinateData.position_latitude !==
          currentCoordinateData.position_latitude ||
        previousCoordinateData.position_longitude !==
          currentCoordinateData.position_longitude
      )
    },
    computeHeading(lat1, lng1, lat2, lng2) {
      const point1 = new window.google.maps.LatLng(lat1, lng1)
      const point2 = new window.google.maps.LatLng(lat2, lng2)
      return window.google.maps.geometry.spherical.computeHeading(
        point1,
        point2
      )
    },
    toIsoString(dateStr) {
      return moment(dateStr).toISOString()
    },
    viewRoute(id) {
      if (id) {
        this.$router.push({ name: 'ShowRouteDetails', params: { routeId: id } })
      }
    },
    async fetchTripRoouteBusStops() {
      if (!this.trip) return
      try {
        const result = await this.fetchBusStops(this.trip.route_id)
        this.routeBusStopIDs = result.data.map((i) => i.id)
      } catch (e) {}
    },
  },
  computed: {
    driverId() {
      return this.trip?.driver?.id
    },
    timeLineVehicleId() {
      if (this.vehicleData && this.vehicleData.tracking_id) {
        return this.vehicleData.id
      }
      return null
    },
    driverName() {
        return this.currentNextInfo?.driver
        ? `${this.currentNextInfo?.driver?.fname || ''} ${this.currentNextInfo?.driver?.lname || ''}`
        : 'N/A'
    },
    isUpcomingTrip() {
      return this.tripId && this.tripId.toString()?.startsWith('__')
    },
    isCompletedTrip() {
      return this.tripId && this.trip && !!this.trip.end_trip
    },
    vehicleData() {
      return this.trip?.vehicle || this.trip?.driver?.vehicle
    },
    partnerData() {
      return this.trip?.vehicle?.partner || this.trip?.vehicle?.partner
    },
    currentUser() {
      return this.$store.getters.currentUser
    },
    token() {
      if (!this.currentUser) return null
      return this.currentUser.token.token
    },
  },
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/partials/typography';

::v-deep .route.link {
  cursor: pointer;

  &:hover {
    color: #00d97e !important;
  }
}

.view-passenger {
  border: 1px solid;
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
  cursor: pointer;
  font-weight: 500;
  color: #109352;
  font-size: 0.8rem;
}

.update-driver {
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.5rem;
  background-color: #b1c2d9;
  color: #ffffff;
  text-align: center;
}

.trip-detail-holder {
  position: relative;
  min-height: 100vh;
  height: 100%;
}

.spanned-map {
  position: absolute;
  left: 0;
  right: 0;
  top: -43px;
  bottom: 0;
  height: 100%;
  width: 100%;
}
</style>
